
import { Component, Prop, Mixins } from 'vue-property-decorator';
import DataFormatControlsContainer, { ChartDataType } from '@/components/DataFormatControlsContainer.vue';
import ResearchPageHeader from '@/components/ResearchPageHeader.vue';
import ResearchsSearchForm, { SearchParamsObject } from '@/components/ResearchsSearchForm.vue';
import {
  ConsolidatedByMarketBySlotSearchParamsDTO,
  ConsolidatedByMarketByProgramSearchParamsDTO,
  ConsolidatedByMarketGroupDTO,
  ConsolidatedByMarketGroupTvNetworkDTO,
  ConsolidatedByMarketDTO,
  ConsolidatedByMarketBySlotExportParams,
  ConsolidatedByMarketByProgramExportParams
} from '@/data/dto/consolidated-by-market.dto';
import { NamedSeriesItem } from '@/data/dto/series-item.dto';
import Highcharts from "highcharts";
import DateTimeUtil from '@/utils/datetime.util';
import HighchartsUtil from '@/utils/highcharts.util';
import HighchartsMixins from '@/mixins/highcharts.mixin';
import ExportButton from '@/components/ExportToExcelButton.vue';
import { numberToBrString } from "@/utils/number.util";

type SearchParamsTypes = ConsolidatedByMarketBySlotSearchParamsDTO | ConsolidatedByMarketByProgramSearchParamsDTO;

@Component({
  components: {
    ResearchPageHeader,
    ResearchsSearchForm,
    DataFormatControlsContainer,
    ExportButton
  }
})
export default class ConsolidatedByMarketChart extends Mixins(HighchartsMixins) {
  @Prop() private searchType!: 'slot' | 'program';

  yKey: ChartDataType = 'audience';

  typeMap = {
    slot: {
      actions: {
        filters: 'getConsolidatedByMarketBySlotFilters',
        data: 'getConsolidatedByMarketBySlot'
      },
      fields: [
        'TARGET', 'SECONDARY_TV_NETWORK', 'WEEKDAYS_CUSTOM_GROUP', 'CUSTOM_TIME_SLOT',
        'CONNECTED_TVS_SUM_TYPE','START_DATE','END_DATE', 'DAYS_TO_OMIT', 'DECIMAL_PLACES',
      ],
    },
    program: {
      actions: {
        filters: 'getConsolidatedByMarketByProgramFilters',
        data: 'getConsolidatedByMarketByProgram'
      },
      fields: [
        'TARGET','MAIN_TV_NETWORK','SECONDARY_TV_NETWORK','WEEKDAYS_CUSTOM_GROUP','PROGRAM',
        'CONNECTED_TVS_SUM_TYPE','START_DATE','END_DATE', 'DAYS_TO_OMIT', 'DECIMAL_PLACES',
      ],
    }
  }

  markets: Record<string, ConsolidatedByMarketGroupTvNetworkDTO> = {};

  get getFiltersAction(): string {
    return this.typeMap[this.searchType].actions.filters;
  }

  get getDataAction(): string {
    return this.typeMap[this.searchType].actions.data;
  }

  get fields(): string[] {
    return this.typeMap[this.searchType].fields;
  }

  get subtitle(): string {
    const searchTypeSubtitles = {
      slot: this.slotSearchTypeSubtitle,
      program: this.programSearchTypeSubtitle,
    };
    return searchTypeSubtitles[this.searchType].toUpperCase();
  }

  get exportAction(): string {
    return {
      slot: 'getConsolidatedByMarketBySlotExcel',
      program: 'getConsolidatedByMarketByProgramExcel',
    }[this.searchType];
  }

  get exportFilename(): string {
    return {
      slot: 'consolidado-praca-faixa.xlsx',
      program: 'consolidado-praca-programa.xlsx',
    }[this.searchType];
  }

  get programSearchTypeSubtitle(): string {
    if (!this.currentSearchParams) return '';
    return `
      ${this.currentSearchParams.completeData.mainTvNetwork?.label || ''} -
      ${this.currentSearchParams.completeData.program?.label || ''} -
      ${this.currentSearchParams.completeData.weekdaysCustomGroup.label} -
      ${DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.startDate)} até
      ${DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.endDate)} -
      ${this.currentSearchParams.completeData.target?.label || ''}
    `;
  }

  get slotSearchTypeSubtitle(): string {
    if (!this.currentSearchParams) return '';
    return `
      ${this.currentSearchParams.completeData.customTimeSlot?.label || ''} -
      ${this.currentSearchParams.completeData.weekdaysCustomGroup.label} -
      ${DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.startDate)} até
      ${DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.endDate)} -
      ${this.currentSearchParams.completeData.target?.label || ''}
    `;
  }

  onChartDataTypeChange(yKey: ChartDataType): void {
    this.yKey = yKey;
    this.mountChartSeriesYAxis(this.chartOptions.series as any, yKey);
  }

  mountChartSeriesYAxis(series: NamedSeriesItem[], yKey = this.yKey): void {
    this.chartOptions.series = series.map((item) => ({
      ...item,
      data: HighchartsUtil.setYAxisValue(item.data, yKey),
    })) as Highcharts.SeriesOptionsType[];
  }

  currentSearchParams: {
    valueOnly: SearchParamsTypes,
    completeData: Record<string, any>
  } | null = null;

  //geração de opções do highcharts através de função, para garantir acesso ao contexto atual do Vue
  //dentro das funções de callback do highcharts (através do parâmetro context)
  generateChartOptions(context: ConsolidatedByMarketChart): Highcharts.Options {
    const generateTooltipConfig = function (): Highcharts.TooltipOptions {
      return {
        enabled: true,
        shared: true,
        useHTML: true,
        formatter: function () {
          const pointsFormat = (s: string, point: Highcharts.TooltipFormatterContextObject): string => {
            return s + `
                <tr>
                    <td style="color: ${point.color}">${point.series.name}:</td><td><b>${numberToBrString(point.y)}</b></td>
                </tr>
            `;
          };

          let title = `<p><small>${this.x}</small>`;

          if (context.searchType === 'program') {
            title += `<br>Exibições: ${context.getMarketExhibitionQuantity(this.x.toString())}`;
          }

          return title + `</p><table>${this.points?.reduce(pointsFormat, '') || ''}</table>`;
        },
      };
    };
    return {
      chart: {
        height: 500,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      plotOptions: {
        series:{
          dataLabels: {
            enabled: true,
            formatter: function(): number | string {
              return numberToBrString(this.y, context.decimalPlaces);
            }
          },
          marker: {
            enabled: true,
            radius: 4,
            symbol: 'circle',
          }
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
      },
      tooltip: generateTooltipConfig(),
      series: [],
    };
  }

  generateChartCategoriesAndLines(context: ConsolidatedByMarketChart, groups: ConsolidatedByMarketGroupDTO[] ): void {
    let markets: { id: string; updatedAt: string; }[] = [];
    let categories:string[] = [];
    let plotLines: any[] = [];
    //ponto atual da linha divisória no eixo X (utiliza .5 para se posicionar entre os grupos)
    let currentX = -0.5;

    groups.forEach(group => {
      //cria um array apenas com as emissoras, extraídas dos seus grupos
      markets = markets.concat(group.markets);
      //incrementa a posição da linha, de acordo com a quantidade de itens no grupo e gera o objeto de configuração da plotLine
      currentX += group.markets.length;
      plotLines.push({
        color: '#ccc',
        dashStyle: 'solid',
        value: currentX,
        width: 2,
      })
    });
    //remove a última linha, que ficaria após o último grupo
    plotLines.pop();
    //gera as categorias a partir dos ids das emissoras
    categories = markets.map(market => market.id);

    const getUpdatedAt = (id:any): string => {
      const item = markets.filter(market => market.id === id)[0];
      if (!item || !item.updatedAt) return '';
      return markets.filter(market => market.id === id)[0].updatedAt;
    }

    //atualiza o config do highcharts
    this.chartOptions.xAxis = {
      categories,
      plotLines,
      labels: {
        formatter: function(): string {
          if (context.searchType === 'slot') {
            return `${this.value}</br>${getUpdatedAt(this.value)}`;
          }
          return `
            ${this.value}
            </br>
            ${context.getMarketExhibitionQuantity(this.value.toString())}
            </br>
            ${getUpdatedAt(this.value)}
          `;
        }
      },
      crosshair: true,
    } as Highcharts.XAxisOptions;
  }

  getMarketExhibitionQuantity(marketId: string): string | number {
    return this.markets?.[marketId].exhibitionsQuantity || '-';
  }

  buildSearchParams(params: SearchParamsObject): SearchParamsTypes {
    if (this.searchType === 'program') {
      return {
        ...params.valueOnly,
        program: params.completeData?.program?.program,
        presentation: params.completeData?.program?.presentation,
        programBoard: params.completeData?.program?.programBoard,
      } as ConsolidatedByMarketByProgramSearchParamsDTO;
    }
    return params.valueOnly as ConsolidatedByMarketBySlotSearchParamsDTO;
  }

  async getConsolidatedByMarket(params: SearchParamsObject): Promise<void> {
    try {
      const searchParams = this.buildSearchParams(params);
      const consolidatedByMarketResponse: ConsolidatedByMarketDTO | null = await this.$store.dispatch(this.getDataAction, searchParams);

      if (!consolidatedByMarketResponse) {
        this.$store.commit('showAlert', {
          message: 'Não há dados disponíveis para a consulta realizada.',
          type: 'warning',
        });
        this.resetContent();
        return;
      }

      const { series, marketGroups } = consolidatedByMarketResponse;
      
      this.chartOptions = this.generateChartOptions(this);
      this.generateChartCategoriesAndLines(this, marketGroups);

      //Transformo o array em um objeto com o id da tvNetwork como chave
      this.markets = marketGroups.reduce((markets, group) => ({
        ...markets,
        ...group.markets.reduce((tvs, item) => ({ ...tvs, [item.id]: item }), {})
      }), {});

      this.mountChartSeriesYAxis(series);
      this.currentSearchParams = {
        valueOnly: searchParams,
        completeData: params.completeData,
      };
    } catch (e) {
      this.resetContent();
    }
  }

  resetContent(): void {
    this.markets = {};
    this.chartOptions.series = [];
    this.currentSearchParams = null;
  }

  get exportToExcelSearchParams():
    ConsolidatedByMarketBySlotExportParams |
    ConsolidatedByMarketByProgramExportParams |
    null
  {
    return {
      slot: this.exportSlotToExcelSearchParams,
      program: this.exportProgramToExcelSearchParams,
    }[this.searchType];
  }

  get exportProgramToExcelSearchParams(): ConsolidatedByMarketByProgramExportParams | null {
    if(!this.currentSearchParams) return null;

    const period = [
      DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.startDate),
      DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.endDate),
    ].join(' até ');

    const headerFields = [
      this.currentSearchParams.completeData?.target?.label,
      this.currentSearchParams.completeData?.weekdaysCustomGroup?.label,
      this.currentSearchParams.completeData?.program?.label,
      period,
      this.currentSearchParams.valueOnly?.connectedTvsSumType
    ];

    return {
      consolidatedByMarketByProgram: {
        params: this.currentSearchParams?.valueOnly as ConsolidatedByMarketByProgramSearchParamsDTO,
        header: headerFields.join(' - '),
      },
    };
  }

  get exportSlotToExcelSearchParams(): ConsolidatedByMarketBySlotExportParams | null {
    if(!this.currentSearchParams) return null;

    const period = [
      DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.startDate),
      DateTimeUtil.stringDateDTOToView(this.currentSearchParams.completeData.endDate),
    ].join(' até ');

    const headerFields = [
      this.currentSearchParams.completeData?.target?.label,
      this.currentSearchParams.completeData?.weekdaysCustomGroup?.label,
      this.currentSearchParams.completeData?.customTimeSlot?.label,
      period,
      this.currentSearchParams.valueOnly?.connectedTvsSumType
    ];

    return {
      consolidatedByMarketBySlot: {
        params: this.currentSearchParams?.valueOnly as ConsolidatedByMarketBySlotSearchParamsDTO,
        header: headerFields.join(' - '),
      },
    };
  }

  onDefaultFiltersValuesLoaded(params: SearchParamsObject): void {
    this.decimalPlaces = params.valueOnly.decimalPlaces || 0;
  }
}
